//
// Push (margins)
// --------------------------------------------------
body {
	.push {
		margin: 20px;
	}
	.push-xxl {
		margin: 60px;
	}
	.push-xl {
		margin: 50px;
	}
	.push-lg {
		margin: 40px;
	}
	.push-md {
		margin: 30px;
	}
	.push-sm {
		margin: 10px;
	}
	.push-xs {
		margin: 5px;
	}
	.push-none {
		margin: 0px;
	}

	.push-top {
		margin-top: 20px;
	}
	.push-top-xxl {
		margin-top: 60px;
	}
	.push-top-xl {
		margin-top: 50px;
	}
	.push-top-lg {
		margin-top: 40px;
	}
	.push-top-md {
		margin-top: 30px;
	}
	.push-top-sm {
		margin-top: 10px;
	}
	.push-top-xs {
		margin-top: 5px;
	}
	.push-top-none {
		margin-top: 0px;
	}

	.push-right {
		margin-right: 20px;
	}
	.push-right-xxl {
		margin-right: 60px;
	}
	.push-right-xl {
		margin-right: 50px;
	}
	.push-right-lg {
		margin-right: 40px;
	}
	.push-right-md {
		margin-right: 30px;
	}
	.push-right-sm {
		margin-right: 10px;
	}
	.push-right-xs {
		margin-right: 5px;
	}
	.push-right-none {
		margin-right: 0px;
	}

	.push-bottom {
		margin-bottom: 20px;
	}
	.push-bottom-button {
		margin-bottom: 100px;
	}
	.push-bottom-xxl {
		margin-bottom: 60px;
	}
	.push-bottom-xl {
		margin-bottom: 50px;
	}
	.push-bottom-lg {
		margin-bottom: 40px;
	}
	.push-bottom-md {
		margin-bottom: 30px;
	}
	.push-bottom-sm {
		margin-bottom: 10px;
	}
	.push-bottom-xs {
		margin-bottom: 5px;
	}
	.push-bottom-none {
		margin-bottom: 0px;
	}

	.push-left {
		margin-left: 20px;
	}
	.push-left-xxl {
		margin-left: 60px;
	}
	.push-left-xl {
		margin-left: 50px;
	}
	.push-left-lg {
		margin-left: 40px;
	}
	.push-left-md {
		margin-left: 30px;
	}
	.push-left-sm {
		margin-left: 10px;
	}
	.push-left-xs {
		margin-left: 5px;
	}
	.push-left-none {
		margin-left: 0px;
	}

}