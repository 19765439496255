@import 'variables';

html {
    .mat-card {
        padding: 0;
        margin: 8px;
        mat-card-header {
            height: auto;
        }
        [matCardAvatar] {
            font-size: $mat-card-header-size;
            line-height: $mat-card-header-size;
            height: $mat-card-header-size;
            width: $mat-card-header-size;
            margin: 16px 0 0 15px;
            border-radius: 50%;
        }
        .mat-card-image {
            width: 100%;
        }
        .mat-card-image,
        .mat-card-lg-image,
        .mat-card-md-image,
        .mat-card-sm-image,
        .mat-card-title-group {
            margin: 0;
        }
        mat-card-title {
            padding-top: $padding;
            padding-left: $padding;
            padding-right: $padding;
        }
        mat-card-subtitle {
            padding-left: $padding;
            padding-right: $padding;
        }
        mat-card-content {
            padding: $padding;
        }
        &, & .mat-card {
            & .mat-card-actions {
                padding: $padding / 2;
                margin: 0;
            }
        }
        .mat-card-actions:last-child {
            margin-bottom: 0px;
            padding-bottom: 8px;
        }
    }
    &:not([dir='rtl']) {
        .mat-card-title-group {
            .mat-card-image,
            .mat-card-lg-image,
            .mat-card-md-image,
            .mat-card-sm-image {
                &:last-child {
                    border-top-right-radius: $mat-card-radius;
                    border-bottom-right-radius: $mat-card-radius;
                }
            }
        }
        .mat-card {
            .mat-card-image {
                &:first-child {
                    border-top-left-radius: $mat-card-radius;
                    border-top-right-radius: $mat-card-radius;
                }
            }
            .mat-card-lg-image,
            .mat-card-md-image,
            .mat-card-sm-image {
                &:first-child {
                    border-top-left-radius: $mat-card-radius;
                }
            }
        }
    }
    &[dir='rtl'] {
        .mat-card-title-group {
            .mat-card-image,
            .mat-card-lg-image,
            .mat-card-md-image,
            .mat-card-sm-image {
                &:last-child {
                    border-top-left-radius: $mat-card-radius;
                    border-bottom-left-radius: $mat-card-radius;
                }
            }
        }
        .mat-card {
            .mat-card-image {
                &:first-child {
                    border-top-left-radius: $mat-card-radius;
                    border-top-right-radius: $mat-card-radius;
                }
            }
            .mat-card-lg-image,
            .mat-card-md-image,
            .mat-card-sm-image {
                &:first-child {
                    border-top-right-radius: $mat-card-radius;
                }
            }
        }
    }
}
