@import 'shadows';

.md-whiteframe-1dp, .md-whiteframe-z1,
.mat-whiteframe-1dp, .mat-whiteframe-z1 {
  box-shadow: $whiteframe-shadow-1dp;
}

.md-whiteframe-2dp,
.mat-whiteframe-2dp {
  box-shadow: $whiteframe-shadow-2dp;
}

.md-whiteframe-3dp,
.mat-whiteframe-3dp {
  box-shadow: $whiteframe-shadow-3dp;
}

.md-whiteframe-4dp, .md-whiteframe-z2,
.mat-whiteframe-4dp, .mat-whiteframe-z2 {
  box-shadow: $whiteframe-shadow-4dp;
}

.md-whiteframe-5dp,
.mat-whiteframe-5dp {
  box-shadow: $whiteframe-shadow-5dp;
}

.md-whiteframe-6dp,
.mat-whiteframe-6dp {
  box-shadow: $whiteframe-shadow-6dp;
}

.md-whiteframe-7dp, .md-whiteframe-z3,
.mat-whiteframe-7dp, .mat-whiteframe-z3 {
  box-shadow: $whiteframe-shadow-7dp;
}

.md-whiteframe-8dp,
.mat-whiteframe-8dp {
  box-shadow: $whiteframe-shadow-8dp;
}

.md-whiteframe-9dp,
.mat-whiteframe-9dp {
  box-shadow: $whiteframe-shadow-9dp;
}

.md-whiteframe-10dp, .md-whiteframe-z4,
.mat-whiteframe-10dp, .mat-whiteframe-z4 {
  box-shadow: $whiteframe-shadow-10dp;
}

.md-whiteframe-11dp,
.mat-whiteframe-11dp {
  box-shadow: $whiteframe-shadow-11dp;
}

.md-whiteframe-12dp,
.mat-whiteframe-12dp {
  box-shadow: $whiteframe-shadow-12dp;
}

.md-whiteframe-13dp, .md-whiteframe-z5,
.mat-whiteframe-13dp, .mat-whiteframe-z5 {
  box-shadow: $whiteframe-shadow-13dp;
}

.md-whiteframe-14dp,
.mat-whiteframe-14dp {
  box-shadow: $whiteframe-shadow-14dp;
}
