//
// Pad (padding)
// --------------------------------------------------
body {
	.pad {
		padding: 20px;
	}
	.pad-xxl {
		padding: 60px;
	}
	.pad-xl {
		padding: 50px;
	}
	.pad-lg {
		padding: 40px;
	}
	.pad-md {
		padding: 30px;
	}
	.pad-sm {
		padding: 10px;
	}
	.pad-xs {
		padding: 5px;
	}
	.pad-none {
		padding: 0px;
	}

	.pad-top {
		padding-top: 20px;
	}
	.pad-top-xxl {
		padding-top: 60px;
	}
	.pad-top-xl {
		padding-top: 50px;
	}
	.pad-top-lg {
		padding-top: 40px;
	}
	.pad-top-md {
		padding-top: 30px;
	}
	.pad-top-sm {
		padding-top: 10px;
	}
	.pad-top-xs {
		padding-top: 5px;
	}
	.pad-top-none {
		padding-top: 0px;
	}

	.pad-right {
    padding-right: 20px;
	}




	.pad-right-xxl {
    padding-right: 60px;
	}

	.pad-right-xl {
    padding-right: 50px;
	}

	.pad-right-lg {
    padding-right: 40px;
	}

	.pad-right-md {
    padding-right: 30px;
	}

	.pad-right-sm {
    padding-right: 10px;
	}

	.pad-right-xs {
    padding-right: 5px;
	}
	
	.pad-right-none {
    padding-right: 0px;
	}

	.pad-bottom {
		padding-bottom: 20px;
	}
	.pad-bottom-button {
		padding-bottom: 100px;
	}
	.pad-bottom-xxl {
		padding-bottom: 60px;
	}
	.pad-bottom-xl {
		padding-bottom: 50px;
	}
	.pad-bottom-lg {
		padding-bottom: 40px;
	}
	.pad-bottom-md {
		padding-bottom: 30px;
	}
	.pad-bottom-sm {
		padding-bottom: 10px;
	}
	.pad-bottom-xs {
		padding-bottom: 5px;
	}
	.pad-bottom-none {
		padding-bottom: 0px;
	}

	.pad-left {
    padding-left: 20px;
	}

	.pad-left-xxl {
    padding-left: 60px;
	}

	.pad-left-xl {
    padding-left: 50px;
	}

	.pad-left-lg {
    padding-left: 40px;
	}

	.pad-left-md {
    padding-left: 30px;
	}

	.pad-left-sm {
    padding-left: 10px;
	}

	.pad-left-xs {
    padding-left: 5px;
	}

	.pad-left-none {
    padding-left: 0px;
	}
}
