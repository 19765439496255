@import 'variables';

mat-sidenav {
    width: $navigation-drawer-width;
    .mat-list-item-content {
        mat-icon {            
            @include rtl(margin-left, 0px,$margin);
            @include rtl(margin-right, $margin, 0px);
            color: #737373;
            fill: #737373;
        }
    }
}
