//
// Pulls (negative margins)
// --------------------------------------------------
body {
	.pull {
		margin: -20px;
	}
	.pull-xxl {
		margin: -40px;
	}
	.pull-xl {
		margin: -30px;
	}
	.pull-lg {
		margin: -20px;
	}
	.pull-md {
		margin: -15px;
	}
	.pull-sm {
		margin: -10px;
	}
	.pull-xs {
		margin: -5px;
	}
	.pull-none {
		margin: -0px;
	}

	.pull-top {
		margin-top: -20px;
	}
	.pull-top-xxl {
		margin-top: -40px;
	}
	.pull-top-xl {
		margin-top: -30px;
	}
	.pull-top-lg {
		margin-top: -20px;
	}
	.pull-top-md {
		margin-top: -15px;
	}
	.pull-top-sm {
		margin-top: -10px;
	}
	.pull-top-xs {
		margin-top: -5px;
	}
	.pull-top-none {
		margin-top: -0px;
	}

	.pull-right-xxl {
		margin-right: -40px;
	}
	.pull-right-xl {
		margin-right: -30px;
	}
	.pull-right-lg {
		margin-right: -20px;
	}
	.pull-right-md {
		margin-right: -15px;
	}
	.pull-right-sm {
		margin-right: -10px;
	}
	.pull-right-xs {
		margin-right: -5px;
	}
	.pull-right-none {
		margin-right: -0px;
	}

	.pull-bottom {
		margin-bottom: -20px;
	}
	.pull-bottom-xxxl {
		margin-bottom: -100px;
	}
	.pull-bottom-xxl {
		margin-bottom: -40px;
	}
	.pull-bottom-xl {
		margin-bottom: -30px;
	}
	.pull-bottom-lg {
		margin-bottom: -20px;
	}
	.pull-bottom-md {
		margin-bottom: -15px;
	}
	.pull-bottom-sm {
		margin-bottom: -10px;
	}
	.pull-bottom-xs {
		margin-bottom: -5px;
	}
	.pull-bottom-none {
		margin-bottom: -0px;
	}

	.pull-left-xxl {
		margin-left: -40px;
	}
	.pull-left-xl {
		margin-left: -30px;
	}
	.pull-left-lg {
		margin-left: -20px;
	}
	.pull-left-md {
		margin-left: -15px;
	}
	.pull-left-sm {
		margin-left: -10px;
	}
	.pull-left-xs {
		margin-left: -5px;
	}
	.pull-left-none {
		margin-left: -0px;
	}
}
